import { get, getDatabase, ref } from "firebase/database";
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const MyContext = createContext();

const DataContext = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [deviceFound, setDeviceFound] = useState(null); // Tracks if device is found
  const [deviceStatus, setDeviceStatus] = useState();

  
  // const navigate = useNavigate();

  const handleConnectDevice = async (deviceId, refresh) => {
    setLoading(true);
    setDeviceFound(false); // Device
    setSelectedDevice(deviceId)
    // console.log("redresh", refresh);
    

    const db = getDatabase();
    const productRef = ref(db, "Products");

    try {
      const snapshot = await get(productRef);
      if (snapshot.exists()) {
        const products = snapshot.val();
        const foundProductKey = Object.keys(products).find(
          (product) => product === deviceId
        );

        if (foundProductKey) {
          const foundProduct = products[foundProductKey];
          setSelectedProduct(foundProduct);
          setDeviceStatus(foundProduct.Status)
          // console.log("foundpr", foundProduct.Status);
          
          // setDeviceStatus(foundProduct.Status)
          setDeviceFound(true); // Device found
          // !refresh &&  toast.success("Cihaza bağlandı !") 
          // navigate('/results'); // Cihaz başarıyla bulunduğunda yönlendir
        } else {
          setDeviceFound(false); // Device not found
          //toast.error("Cihaz bulunamadı.");
        }
      } else {
        //toast.error("Ürünler bulunamadı.");
      }
    } catch (error) {
      console.error("Database error:", error);
      //toast.error("Bir hata oluştu.");
    } finally {
      setLoading(false);
    setDeviceFound(false); // Cihaz bulundu

    }
  };

  return (
    <MyContext.Provider
      value={{
        handleConnectDevice,
        loading,
        selectedProduct,
        deviceFound,
        setDeviceFound,
        selectedDevice,
        deviceStatus,
        setDeviceStatus
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, DataContext };
