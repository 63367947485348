// src/pages/ForgotPassword.js
import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import LoadingSpinner from "../components/LoadingSpinner";
import { Link } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: { main: "#2563eb" },
    secondary: { main: "#383938" },
  },
});

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState({ type: '', message: '' });
  const navigate = useNavigate();
  const auth = getAuth();
  const { user } = useAuth();

  const handleResetPassword = async (event) => {
    event.preventDefault();
    setStatus({ type: '', message: '' });
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      setStatus({
        type: 'success',
        message: 'Password reset link has been sent to your email. You will be redirected to login page.'
      });
      setTimeout(() => navigate("/login"), 5000);
    } catch (err) {
      setStatus({
        type: 'error',
        message: `Failed to send reset email: ${err.message}`
      });
    } finally {
      setLoading(false);
    }
  };

  if (user) {
    navigate("/dashboard");
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <LoadingSpinner loading={true} />}
      <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-md p-6 sm:p-8 w-full max-w-md">
          <h1 className="text-2xl font-semibold text-center text-blue-600 sm:text-3xl">
            Forgot Password?
          </h1>
          <p className="text-center text-gray-500 mt-2 text-sm sm:text-base">
            Enter your email to reset your password
          </p>

          {status.message && (
            <div className={`mt-4 p-3 rounded-md text-sm ${
              status.type === 'success' 
                ? 'bg-green-50 text-green-700 border border-green-200'
                : 'bg-red-50 text-red-700 border border-red-200'
            }`}>
              {status.message}
            </div>
          )}

          <form className="mt-6 space-y-4" onSubmit={handleResetPassword}>
            <div>
              <input
                variant="outlined"
                margin="normal"
                className="w-full bg-[#F1F4FF] px-4 py-3 text-sm border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                required
                id="email"
                name="email"
                autoComplete="email"
                placeholder="Email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={isLoading}
              className="w-full py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 sm:py-3 disabled:opacity-50"
            >
              Reset Password
            </button>
          </form>

          <div className="text-center text-gray-500 mt-4 text-sm sm:text-base">
            <Link href="/login" className="text-blue-600 hover:underline">
              Go back to Login
            </Link>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
