import React from 'react';

const PageWrapper = ({ children }) => {
  return (
    <div className="pt-14"> {/* Increased from pt-8 to pt-14 for more top spacing */}
      {children}
    </div>
  );
};

export default PageWrapper; 