import React, { useState } from "react";
import { ThemeProvider, Link } from "@mui/material";

import { createTheme } from "@mui/material/styles";
import Lock from "@mui/icons-material/Lock";
import Mail from "@mui/icons-material/Mail";
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database"; // Importing set and ref for Realtime Database
import { useNavigate } from "react-router-dom";
import Logo from "../assets/logo.jpg";
import googlePng from "../assets/google.png";

const theme = createTheme({
  palette: {
    primary: { main: "#2563eb" },
    secondary: { main: "#383938" },
  },
});

const useStyles = {
  container: {
    backgroundImage: `url(${Logo})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    position: "relative",
    zIndex: 0,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Darker overlay for better contrast
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid gray",
    padding: "40px",
    backgroundImage:
      "linear-gradient(360deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7))",
    borderRadius: "24px",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    width: "40%",
    "@media (max-width:600px)": {
      minWidth: "90%",
      padding: "20px",
    },
  },
  button: {
    marginTop: "20px",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: "#b22222", // Change color on hover
    },
  },
};
export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords don't match!");
      return;
    }

    const db = getDatabase(); // Initialize the Realtime Database

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Store user information in the Realtime Database
      await set(ref(db, "MobileUsers/" + user.uid), {
        email: user.email,
        uid: user.uid,
        // Add any additional user info here
      });

      alert("Registration successful!");
      navigate("/login"); // Redirect to login
    } catch (error) {
      console.error("Registration error:", error);
      alert("Registration failed: " + error.message);
    }
  };

  const handleGoogleSignup = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      // Store user information in the Realtime Database
      const db = getDatabase();
      await set(ref(db, "MobileUsers/" + user.uid), {
        email: user.email,
        uid: user.uid,
      });

      alert("Google registration successful!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Google registration error:", error);
      alert("Google registration failed: " + error.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-md p-6 sm:p-8 w-full max-w-md">
          <h1 className="text-2xl font-semibold text-center text-blue-600 sm:text-3xl">
            Create Account
          </h1>
          <p className="text-center text-gray-500 mt-2 text-sm sm:text-base">
            Create an account to control your devices
          </p>
          <form className="mt-6 space-y-4" onSubmit={handleSubmit}>
            <div>
              <input
                variant="outlined"
                margin="normal"
                className="w-full bg-[#F1F4FF] px-4 py-3 text-sm border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                required
                id="email"
                name="email"
                autoComplete="email"
                placeholder="Email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                className="w-full bg-[#F1F4FF]  px-4 py-3 text-sm border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                variant="outlined"
                margin="normal"
                required
                name="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Confirm Password"
                className="w-full bg-[#F1F4FF] px-4 py-3 text-sm border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                variant="outlined"
                margin="normal"
                required
                name="confirmPassword"
                id="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 sm:py-3"
              sx={useStyles.button}
            >
              Sign up
            </button>
          </form>
          <p className="text-center text-gray-500 mt-2 text-sm sm:text-base">
            Or continue with
          </p>
          <div className="flex w-full justify-center space-x-4 mt-4">
            <button
              type="button"
              className="w-full flex items-center justify-center gap-2 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 sm:py-3"
              sx={useStyles.button}
              onClick={handleGoogleSignup}
            >
              <img
                src={googlePng}
                alt="Google"
                style={{ width: "24px", height: "24px" }}
              />
              Sign up with Google
            </button>
          </div>

          <div className="text-center text-gray-500 mt-4 text-sm sm:text-base">
            <Link href="/login" className="text-blue-600 hover:underline">
              Already have an account Sign In
            </Link>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
