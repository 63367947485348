import React, { useContext, useState, useEffect, useRef } from "react";
import { createTheme } from "@mui/material/styles";
import Device from "../assets/device.jpg"; // Your logo path here
import { MyContext } from "../context/dataContextProvider"; // Context'i içe aktar
import { getDatabase, ref, update } from "firebase/database"; // Firebase'den gerekli fonksiyonları içe aktar
import { useAuth } from "../utils/useAuth";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  Grid,
  Box,
  Button,
  Typography,
  CssBaseline,
  Paper,
  CardContent,
  IconButton,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PowerIcon from "@mui/icons-material/Power"; // Open icon
import PowerOffIcon from "@mui/icons-material/PowerOff"; // Close icon
import PressureImage from "../assets/pressure.png";
import PressureImage2 from "../assets/pressure2.png";
import Temperature from "../assets/temperature.png";
import PpmImage from "../assets/ppm.png";
import Produced from "../assets/produced.png";
import Washer from "../assets/pressure-washer.png";
import { toast } from "react-toastify";
import PageWrapper from "../components/PageWrapper";

const theme = createTheme({
  palette: {
    primary: { main: "#2563eb" },
    secondary: { main: "#383938" },
  },
});
const useStyles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 8,
  },
  card: {
    background: "rgba(255, 255, 255, 0.9)",
    borderRadius: "16px",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
    width: "80%",
    maxWidth: "600px",
    padding: "10px",
  },
  title: {
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "5px",
    color: "white",
  },
  tableContainer: {
    marginTop: "5px",
    display: "flex",
  },
  table: {
    borderRadius: "15px",
    backgroundColor: "#C4D7FF",
    margin: "1px",
    // border:"1px solid black"
    // color:"red"
  },
  tableCell: {
    padding: "4px 8px", // Reduced padding for a compact look
    borderRadius: "15px",
  },
};

const DeviceStatus = () => {
  const {
    selectedProduct,
    handleConnectDevice,
    deviceStatus,
    setDeviceStatus,
  } = useContext(MyContext);
  const { user } = useAuth();
  const navigate = useNavigate();
  const selectedDevice = localStorage.getItem("deviceId");
  const [washingPopupOpen, setWashingPopupOpen] = useState(false);
  const [startupPopupOpen, setStartupPopupOpen] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [countRef, setCountRes] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const selectedProductRef = useRef(selectedProduct);
  const [ntcErrorOpen, setNtcErrorOpen] = useState(false);
  const [isPageVisible, setIsPageVisible] = useState(true);
  const [isAppFocused, setIsAppFocused] = useState(true);

  useEffect(() => {
    selectedProductRef.current = selectedProduct;
  }, [selectedProduct]);

  useEffect(() => {
    handleConnectDevice(selectedDevice);
  }, [selectedDevice]);

  useEffect(() => {
    function handleVisibilityChange() {
      setIsPageVisible(!document.hidden);
    }

    function handleAppStateChange() {
      if (document.visibilityState === 'visible') {
        setIsAppFocused(true);
        // Refresh data when app comes to foreground
        if (selectedDevice) {
          handleConnectDevice(selectedDevice, true);
        }
      } else {
        setIsAppFocused(false);
      }
    }

    // Handle both visibility change and page focus
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", () => setIsAppFocused(true));
    window.addEventListener("blur", () => setIsAppFocused(false));
    
    // Mobile specific events
    if ('onpagehide' in window) {
      window.addEventListener('pagehide', () => setIsAppFocused(false));
      window.addEventListener('pageshow', handleAppStateChange);
    }

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", () => setIsAppFocused(true));
      window.removeEventListener("blur", () => setIsAppFocused(false));
      if ('onpagehide' in window) {
        window.removeEventListener('pagehide', () => setIsAppFocused(false));
        window.removeEventListener('pageshow', handleAppStateChange);
      }
    };
  }, [selectedDevice, handleConnectDevice]);

  useEffect(() => {
    let interval;
    let refresh;
    
    if (deviceStatus && isPageVisible && isAppFocused) {
      interval = setInterval(() => {
        const currentNTC = selectedProductRef.current?.NTC;
        setCountRes(countRef + 1);
        if (selectedDevice) {
          handleConnectDevice(selectedDevice, (refresh = true));

          if (!selectedProduct.Status) {
            setDeviceStatus(false);
          }
          console.log(selectedProduct.Washing);
          if (selectedProduct.Washing) {
            setWashingPopupOpen(true);
            setStartupPopupOpen(false);
          }
          console.log(countRef)
          if ((currentNTC == 0) && countRef >= 3) {
            setDeviceStatus(false)
            setStartupPopupOpen(false);
            handleStatusChange(false)
            setNtcErrorOpen(true);
          }
        }
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [selectedDevice, deviceStatus, handleConnectDevice, isPageVisible, isAppFocused]);

  useEffect(() => {
    let interval;
    let refresh;

    if (!deviceStatus && isPageVisible && isAppFocused) {
      interval = setInterval(() => {
        if (selectedDevice) {
          handleConnectDevice(selectedDevice, (refresh = true));
          if (selectedProduct.Status) {
            setDeviceStatus(true);
          }
          if (selectedProduct.NTC == 0) {
            setWashingPopupOpen(false);
          }
        }
      }, 10000);
    }

    return () => clearInterval(interval);
  }, [selectedDevice, deviceStatus, handleConnectDevice, isPageVisible, isAppFocused]);

  useEffect(() => {    
    if ((deviceStatus && isFirstRender)) {
      setIsFirstRender(false); // İlk render'dan sonra durumu güncelle
      return; // Eğer deviceStatus açıksa hiçbir şey yapma
    }
    if ((deviceStatus == false && isFirstRender)) {
      setIsFirstRender(false); // İlk render'dan sonra durumu güncelle
      return; // Eğer deviceStatus açıksa hiçbir şey yapma
    }
    else  if (deviceStatus && !isFirstRender) {
      setStartupPopupOpen(true);
      setCountdown(7);

      const countdownInterval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            checkNTCValue();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
    else if(!deviceStatus){
      setStartupPopupOpen(false);
      setCountRes(0)
    }
  }, [deviceStatus]);

  const checkNTCValue = () => {
    const currentNTC = selectedProductRef.current?.NTC;

    if (currentNTC === 0) {
      setNtcErrorOpen(true);
      handleStatusChange(false);
      setWashingPopupOpen(false);
      setDeviceStatus(false);
    }

    setStartupPopupOpen(false);
  };
  const handleStatusChange = async (newStatus) => {
    setDeviceStatus(newStatus);
    let refresh;

    const db = getDatabase();
    const deviceRef = ref(db, `Products/${selectedDevice}`);

    try {
      await update(deviceRef, { Status: newStatus });

      const logRef = ref(db, `Products/${selectedDevice}/log`);
      const newLogEntry = {
        Updated_at: new Date().toISOString(),
        status: newStatus,
        UserID: user.uid,
        product: selectedDevice,
        userName: user.email,
      };

      await update(logRef, { [new Date().getTime()]: newLogEntry });
      handleConnectDevice(selectedDevice, (refresh = true));
    } catch (error) {
      console.error("Durum güncellenirken hata oluştu:", error);
    }
  };

  // Washing değeri false olduğunda popup'ı kapat
  useEffect(() => {
    if (selectedProduct && !selectedProduct.Washing) {
      setWashingPopupOpen(false);
    }
  }, [selectedProduct]);
  return (
    <ThemeProvider theme={theme}>
      <PageWrapper>
        <div className="flex flex-col min-h-screen bg-gray-100 px-4 py-2 sm:px-6 sm:py-4 lg:px-8">
          <div className="bg-gray-50 rounded-lg shadow-md p-4 sm:p-6 w-full max-w-4xl mx-auto my-2">
            {/* Header Section - Modified to stay horizontal on mobile */}
            <div className="flex flex-row justify-between items-center gap-4 mb-6">
              <button
                onClick={() => navigate("/dashboard")}
                className="px-3 py-1.5 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
              >
                New Device
              </button>
              
              <div className="flex items-center gap-2">
                <span className="text-gray-600 text-sm">Device Status:</span>
                <button
                  onClick={() => handleStatusChange(!deviceStatus)}
                  className={`w-12 h-6 rounded-full relative transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 ${
                    deviceStatus ? 'bg-blue-600' : 'bg-gray-300'
                  }`}
                >
                  <span
                    className={`absolute top-0.5 left-0.5 w-5 h-5 rounded-full bg-white transition-transform duration-300 ${
                      deviceStatus ? 'transform translate-x-6' : ''
                    }`}
                  />
                </button>
              </div>
            </div>

            {selectedDevice && (
              <p className="text-gray-600 mb-20 text-sm text-center">
                Connected Device: <span className="font-semibold">{selectedDevice}</span>
              </p>
            )}

            {selectedProduct ? (
              <div className="grid grid-cols-2 md:grid-cols-3 gap-3 justify-items-center">
                {/* LP Card */}
                <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-100 w-full hover:shadow-md transition-shadow duration-200 hover:border-gray-200">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col mb-1">
                      <h3 className="text-base font-semibold text-gray-800">LP</h3>
                      <div className="flex items-baseline">
                        <span className="text-xl font-bold text-blue-600">{selectedProduct.LP}</span>
                        <span className="ml-1 text-sm text-gray-600">bar</span>
                      </div>
                    </div>
                    <img
                      src={PressureImage2}
                      alt="Low Pressure"
                      className="w-8 h-8"
                    />
                  </div>
                </div>

                {/* HP Card */}
                <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-100 w-full hover:shadow-md transition-shadow duration-200 hover:border-gray-200">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col mb-1">
                      <h3 className="text-base font-semibold text-gray-800">HP</h3>
                      <div className="flex items-baseline">
                        <span className="text-xl font-bold text-blue-600">{selectedProduct.HP}</span>
                        <span className="ml-1 text-sm text-gray-600">bar</span>
                      </div>
                    </div>
                    <img
                      src={PressureImage}
                      alt="Pressure"
                      className="w-8 h-8"
                    />
                  </div>
                </div>

                {/* Salinity Card */}
                <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-100 w-full hover:shadow-md transition-shadow duration-200 hover:border-gray-200">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col mb-1">
                      <h3 className="text-base font-semibold text-gray-800">Salinity</h3>
                      <div className="flex items-baseline">
                        <span className="text-xl font-bold text-blue-600">{selectedProduct.PPM}</span>
                        <span className="ml-1 text-sm text-gray-600">ppm</span>
                      </div>
                    </div>
                    <img
                      src={PpmImage}
                      alt="PPM"
                      className="w-8 h-8"
                    />
                  </div>
                </div>

                {/* Washing Status Card */}
                <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-100 w-full hover:shadow-md transition-shadow duration-200 hover:border-gray-200">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col mb-1">
                      <h3 className="text-base font-semibold text-gray-800">Washing</h3>
                      <div className="flex items-baseline">
                        <span className={`text-base font-semibold ${
                          selectedProduct.Washing ? 'text-blue-600' : 'text-gray-600'
                        }`}>
                          {selectedProduct.Washing ? 'Active' : 'Inactive'}
                        </span>
                      </div>
                    </div>
                    <img
                      src={Washer}
                      alt="Washing"
                      className="w-8 h-8 ml-2"
                    />
                  </div>
                </div>

                {/* Produced Card */}
                <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-100 w-full hover:shadow-md transition-shadow duration-200 hover:border-gray-200">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col mb-1">
                      <h3 className="text-base font-semibold text-gray-800">Produced</h3>
                      <div className="flex items-baseline">
                        <span className="text-xl font-bold text-blue-600">{selectedProduct.Produced}</span>
                        <span className="ml-1 text-sm text-gray-600">litre</span>
                      </div>
                    </div>
                    <img
                      src={Produced}
                      alt="Produced"
                      className="w-8 h-8"
                    />
                  </div>
                </div>

                {/* NTC Card */}
                <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-100 w-full hover:shadow-md transition-shadow duration-200 hover:border-gray-200">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col mb-1">
                      <h3 className="text-base font-semibold text-gray-800">NTC</h3>
                      <div className="flex items-baseline">
                        <span className="text-xl font-bold text-blue-600">{selectedProduct.NTC}</span>
                        <span className="ml-1 text-sm text-gray-600">°C</span>
                      </div>
                    </div>
                    <img
                      src={Temperature}
                      alt="Temperature"
                      className="w-8 h-8"
                      style={{
                        filter: 'invert(1)',
                        opacity: '0.8'
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-center text-gray-600">No device information available.</p>
            )}

            {/* Washing Dialog */}
            <Dialog 
              open={washingPopupOpen}
              PaperProps={{
                style: {
                  borderRadius: '12px',
                  padding: '8px'
                }
              }}
            >
              <div className="p-4 sm:p-6">
                <div className="flex items-center gap-3 mb-4">
                  <img
                    src={Washer}
                    alt="Washing"
                    className="w-8 h-8"
                  />
                  <DialogTitle className="p-0 text-lg font-semibold">
                    Washing in Progress
                  </DialogTitle>
                </div>
                
                <DialogContent className="p-0 mb-4">
                  <div className="flex flex-col items-center">
                    <div className="w-full h-1 bg-blue-100 rounded-full mb-4">
                      <div className="w-1/2 h-1 bg-blue-600 rounded-full animate-[wash_2s_ease-in-out_infinite]"></div>
                    </div>
                    <p className="text-gray-600 text-sm">
                      Device is currently in washing mode. Please wait...
                    </p>
                  </div>
                </DialogContent>
              </div>
            </Dialog>

            <Dialog 
              open={startupPopupOpen} 
              PaperProps={{
                style: {
                  borderRadius: '12px',
                  padding: '8px'
                }
              }}
            >
              <div className="p-4 sm:p-6">
                <div className="flex items-center gap-3 mb-4">
                  <div className="w-6 h-6 rounded-full bg-blue-100 flex items-center justify-center">
                    <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                  </div>
                  <DialogTitle className="p-0 text-lg font-semibold text-blue-600">
                    Device Starting
                  </DialogTitle>
                </div>
                
                <DialogContent className="p-0 mb-4">
                  <div className="flex flex-col items-center">
                    <div className="w-full h-1 bg-blue-100 rounded-full mb-4">
                      <div 
                        className="h-1 bg-blue-600 rounded-full transition-all duration-1000 ease-in-out"
                        style={{ width: `${(countdown / 7) * 100}%` }}
                      ></div>
                    </div>
                    <p className="text-gray-600 text-sm">
                      Device is initializing. Please wait {countdown} seconds...
                    </p>
                  </div>
                </DialogContent>

                <div className="flex justify-end">
                  <button
                    onClick={() => setStartupPopupOpen(false)}
                    className="px-4 py-2 text-sm font-medium text-blue-600 hover:bg-blue-50 rounded-md transition-colors"
                  >
                    Close
                  </button>
                </div>
              </div>
            </Dialog>

            {/* NTC Error Dialog */}
            <Dialog 
              open={ntcErrorOpen}
              PaperProps={{
                style: {
                  borderRadius: '12px',
                  padding: '8px'
                }
              }}
            >
              <div className="p-4 sm:p-6">
                <div className="flex items-center gap-3 mb-4">
                  <div className="w-6 h-6 rounded-full bg-red-100 flex items-center justify-center">
                    <svg className="w-5 h-5 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                  </div>
                  <DialogTitle className="p-0 text-lg font-semibold text-red-600">
                    Device Error
                  </DialogTitle>
                </div>
                
                <DialogContent className="p-0 mb-4">
                  <div className="flex flex-col items-center">
                    <p className="text-gray-600 text-sm">
                      Please check your device. Be sure to connect the device to the power source.
                    </p>
                  </div>
                </DialogContent>

                <div className="flex justify-end">
                  <button
                    onClick={() => setNtcErrorOpen(false)}
                    className="px-4 py-2 text-sm font-medium text-red-600 hover:bg-red-50 rounded-md transition-colors"
                  >
                    Close
                  </button>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </PageWrapper>
      <style jsx>{`
        @keyframes wash {
          0% { transform: translateX(-100%); }
          100% { transform: translateX(100%); }
        }
      `}</style>
    </ThemeProvider>
  );
};

export default DeviceStatus;
