// src/components/Navbar.js
import React from "react";
import { getAuth } from "firebase/auth";
import { useAuth } from '../utils/useAuth';
import LogoutIcon from '../assets/logout.png';

const Navbar = () => {
  const { user } = useAuth();
  const auth = getAuth();
  
  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.removeItem("deviceId"); // Remove deviceId
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <header className="absolute top-0 left-0 right-0 bg-white shadow-sm z-50">
      <div className="flex items-center justify-between px-4 py-3">
        <h1 className="text-xl font-semibold text-gray-800">
          WaterMaker
        </h1>
        
        {user && (
          <button
            onClick={handleLogout}
            className="p-2 rounded-full hover:bg-gray-100 transition-colors"
          >
            <img
              src={LogoutIcon}
              alt="Logout"
              className="w-6 h-6"
              style={{
                filter: 'invert(1)',
                opacity: '0.8'
              }}
            />
          </button>
        )}
      </div>
    </header>
  );
};

export default Navbar;